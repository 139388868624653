import axios from "../axios/axios";

export const LanguageSupportRepository = {
    create: (data) => {
        return axios.post(`/api/language-support`, data)
    },
    getAll: () => {
        return axios.get(`/api/language-support`)
    },
    edit: (id, data) => {
        return axios.put(`/api/language-support/${id}`, data)
    }
}