import React, {useState} from "react";
import {
    Box,
    Button,
    Divider,
    Grid,
    IconButton,
    InputAdornment,
    Stack,
    TextField,
    Toolbar,
    Typography
} from "@mui/material";
import {blue, grey, teal} from "@mui/material/colors";
import languages from "../../assets/languages.png";
import logo from "../../assets/logo.png";
import {Keyboard, Logout, OpenInNew} from "@mui/icons-material";
import {AUTH_TOKEN} from "../../axios/axios";
import {useNavigate} from "react-router-dom";
import {SessionRepository} from "../../repository/SessionRepository";
import {toast} from "react-toastify";

export const HomePage = () => {
    const [code, setCode] = useState("");

    const createSession = () => {
        SessionRepository.create()
            .then(response => {
                const url = `${process.env.REACT_APP_CLIENT}/meet/${response.data?.id}`;
                openSession(url);
            })
            .catch(error => toast.error(error.message))
    }

    const openMeeting = (event) => {
        event?.preventDefault();
        if (code) {
            // if User paste URL replace https://localhost:3000
            const url = `${process.env.REACT_APP_CLIENT}/meet/`;
            const uid = code
                .replaceAll(url, '')
                .replaceAll("/", "");
            openSession(url + uid);
        }
    }

    const openSession = (url) => {
        window.open(url, "_blank");
    }

    return (
        <>
            <Toolbar sx={{position: "fixed", width: "100%", zIndex: ""}}>
                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={{width: "100%"}}>
                    <img src={logo} alt={"logo"} style={{maxWidth: "50px"}} width={"100%"} height={"auto"}/>
                    <IconButton
                        onClick={() => {
                            localStorage.removeItem(AUTH_TOKEN);
                            window.location.reload();
                        }}
                    >
                        <Logout/>
                    </IconButton>
                </Stack>
            </Toolbar>
            <Grid container sx={{height: "100%"}}>
                <Grid item xs={12} md={6} lg={5}>
                    <Stack direction={"column"} sx={{width: "100%", height: "100%", p: 3}} gap={2}
                           justifyContent={"center"} alignItems={"flex-start"}>
                        <Box>
                            <Typography sx={{fontFamily: "serif !important", py: 0}} fontSize={40} color={grey[800]}
                                        fontWeight={"bold"}>
                                OpenBrain Meet
                            </Typography>
                            <Typography sx={{fontFamily: "serif !important", py: 0}} fontSize={20} color={grey[700]}>
				OpenBrain Meet provides Near Real-Time AI-based Voice Translation For All Your Meetings and Conferences.
                            </Typography>
                        </Box>
                        <Stack direction={"row"} sx={{width: '100%'}} gap={2}>
                            <Button variant={"outlined"} sx={{
                                background: teal[300],
                                borderColor: teal[300],
                                color: teal[50],
                                "&:hover": {
                                    background: teal[100],
                                    borderColor: teal[100],
                                    color: teal[300],
                                }
                            }} onClick={createSession}>
                                Create Instant meeting
                            </Button>
                            <TextField
                                label={"Enter code or URL"}
                                size={"small"}
                                sx={{flex: 1, background: grey[200]}}
                                value={code}
                                onKeyDown={(ev) => {
                                    if (ev.key === 'Enter') {
                                        // Do code here
                                        openMeeting(ev)
                                    }
                                }}
                                onChange={(event) => setCode(event.target.value)}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            disabled={code === ""}
                                            color={code ? "primary" : "default"}
                                            size={"small"}
                                            onClick={openMeeting}
                                            edge="end"
                                        >
                                            <OpenInNew sx={{fontSize: 20}}/>
                                        </IconButton>
                                    </InputAdornment>
                                }}
                            />
                        </Stack>
                        {/*<Divider>OR</Divider>*/}
                    </Stack>
                </Grid>
                <Grid item md={6} lg={7} sx={{display: {xs: "none", md: "block"}}}>
                    <Stack direction={"column"} sx={{width: "100%", height: "100%", p: 3}} gap={1}
                           justifyContent={"center"} alignItems={"center"}>
                        <img src={languages} alt={"languages"} style={{maxWidth: "500px"}} width={"100%"}
                             height={"auto"}/>
                    </Stack>
                </Grid>
            </Grid>
        </>
    )
}
