import {
    Box,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    Modal,
    Stack,
    Typography
} from "@mui/material";
import * as Flags from "country-flag-icons/react/3x2";
import {green, grey} from "@mui/material/colors";
import React from "react";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    border: 0,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

export const SelectLanguageModal = ({open, handleClose, languages, onSelect, selectedLanguage}) => {
    const selectLanguage = (lang) => {
        onSelect(lang);
        handleClose();
    }

    return (
        <Modal
            open={open}
            // onClose={handleClose}
            aria-labelledby="modal-modal-title"
        >
            <Box sx={style}>
                <List
                    sx={{width: '100%', bgcolor: 'background.paper'}}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                        <ListSubheader sx={{px: 0}} component="div" id="nested-list-subheader">
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                Select translation language
                            </Typography>
                        </ListSubheader>
                    }
                >

                    {
                        languages?.map(language => {
                            let Flag = null;
                            if (Flags[language.icon]) {
                                Flag = Flags[language.icon];
                            }
                            return (
                                <ListItemButton
                                    key={language?.id}
                                    sx={{
                                        border: 1,
                                        borderColor: "divider",
                                        borderRadius: 2,
                                        p: 1,
                                        my: 1,
                                        bgcolor: selectedLanguage?.id === language?.id ? green[100] : grey[100],
                                        color: selectedLanguage?.id === language?.id ? green[700] : grey[800],
                                        "&:hover": {
                                            boxShadow: 4,
                                            cursor: "pointer",
                                            bgcolor: selectedLanguage?.id === language?.id ? green[200] : green[50],
                                            color: green[700],
                                        }
                                    }}
                                    onClick={() => selectLanguage(language)}
                                >
                                    <ListItemIcon>
                                        {Flag && <Flag height={"20"}/>}
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography component={"span"}>{language?.language}</Typography>} />
                                </ListItemButton>
                            )
                        })
                    }

                </List>
            </Box>
        </Modal>
    )
}