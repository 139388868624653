import React, {useContext, useState} from "react";
import {
    Button,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    RadioGroup,
    TextField,
    Typography,
    Radio,
    Stack
} from "@mui/material";
import logo from "../../assets/logo.png";
import axios, {AUTH_TOKEN} from "../../axios/axios";
import {AuthContext} from "../../context/AuthContext";
import {Link, useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {UserRepository} from "../../repository/UserRepository";

export const RegisterPage = () => {
    const [registerForm, setRegisterForm] = useState({
        username: "",
        email: "",
        name: "",
        lastname: "",
        password: "",
        gender: "M"
    });
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const onChange = (event) => {
        const value = event.target.value;
        const key = event.target.name;

        setRegisterForm(prevState => {
            return {
                ...prevState,
                [key]: value
            }
        })
    }

    const loginAttempt = (e) => {
        e?.preventDefault();
        for (const key of Object.keys(registerForm)) {
            const value = registerForm[key];
            if (!value) {
                toast.error(`All fields are required!`)
                return;
            }
        }

        UserRepository.register(registerForm)
            .then(() => {
                navigate("/login")
            })
            .catch(error => toast.error(error.message));
    }


    return (
        <Grid container xs={12} justifyContent={"center"} alignItems={"center"} sx={{width: "100%", height: "100%"}}>
            <Grid item gap={2} sx={{maxWidth: "400px", width: "100%", borderColor: "divider", p: 3, textAlign: "center"}}>
                <img src={logo} alt={"logo"} width={"120px"} height={"auto"} />
                <Typography sx={{my: 2}} variant={"h5"}>Welcome to OpenBrain meet</Typography>
                <Typography sx={{my: 2}} variant={"subtitle2"}>Please enter your credentials</Typography>
                <Stack direction={"row"} gap={2}>
                    <TextField
                      label={"Name"}
                      type={"text"}
                      name={"name"}
                      sx={{my: 2}}
                      fullWidth
                      variant={"standard"}
                      value={registerForm?.name}
                      onChange={onChange}
                    />
                    <TextField
                      label={"Lastname"}
                      type={"text"}
                      name={"lastname"}
                      sx={{my: 2}}
                      fullWidth
                      variant={"standard"}
                      value={registerForm?.lastname}
                      onChange={onChange}
                    />
                </Stack>
                <FormControl sx={{textAlign: "left", width: "100%"}}>
                    <FormLabel id="demo-radio-buttons-group-label">Gender</FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="M"
                      name="gender"
                      value={registerForm.gender}
                      onChange={onChange}
                      row
                    >
                        <FormControlLabel value="M" control={<Radio />} label="Male" />
                        <FormControlLabel value="F" control={<Radio />} label="Female" />
                    </RadioGroup>
                </FormControl>
                <TextField
                    label={"Username"}
                    type={"text"}
                    name={"username"}
                    sx={{my: 2}}
                    fullWidth
                    variant={"standard"}
                    value={registerForm?.username}
                    onChange={onChange}
                />
                <TextField
                    label={"Email"}
                    type={"text"}
                    name={"email"}
                    sx={{my: 2}}
                    fullWidth
                    variant={"standard"}
                    value={registerForm?.email}
                    onChange={onChange}
                />

                <TextField
                    label={"Password"}
                    type={"password"}
                    name={"password"}
                    sx={{my: 2}}
                    fullWidth
                    variant={"standard"}
                    value={registerForm?.password}
                    onChange={onChange}
                />
                <Button
                    sx={{my: 2}}
                    fullWidth
                    variant={"contained"}
                    onClick={loginAttempt}
                >
                    Register
                </Button>

                <Typography color={"error"}>
                    {error}
                </Typography>

                <Divider>
                    OR
                </Divider>

                <Button
                    fullWidth
                    component={Link}
                    to={"/login"}
                    variant={"text"}
                    size={"small"}
                    sx={{my: 2, color: "primary", textTransform: "uppercase"}}
                >
                    Login
                </Button>
            </Grid>
        </Grid>
    )
}