import React, {useContext, useEffect, useMemo, useState} from "react";
import {Grid} from "@mui/material";
import {grey} from "@mui/material/colors";
import {DisplayActiveUsers} from "./components/DisplayActiveUsers";
import {DisplayChat} from "./components/DisplayChat";
import {MessageRepository} from "../../repository/MessageRepository";
import {toast} from "react-toastify";
import {AuthContext} from "../../context/AuthContext";
import useDebounce from "../../hooks/useDebounce";

export const Session = ({session, languages, currentMessage, messages, activeUsers, onSelectLanguage, selectedLanguage, isMicMuted, setIsMicMuted, isChatOpen, setIsChatOpen, updateTalking}) => {
    const [message, setMessage] = useState("");

    const [audioMessage, setAudioMessage] = useState("");
    const audioMessageDebounced = useDebounce(audioMessage, 1000);


    useEffect(() => {
        if (audioMessageDebounced) {
            setAudioMessage("");
            onSendMessage(audioMessageDebounced);
        }
    }, [audioMessageDebounced])

    const authContext = useContext(AuthContext);

    const onSendMessage = (message) => {
        if (message) {
            const data = {
                session: session?.id,
                text: message,
                sender: authContext?.user?.username,
                languageSupportDto: selectedLanguage
            };
            MessageRepository.create(session?.id, data)
                .then(response => {
                    setMessage("");
                })
                .catch(error => {
                    toast.error("Message failed to send!")
                })
        }
    }

    const translatedMessages = useMemo(() => {
        return messages?.filter(message => message?.language?.id === selectedLanguage?.id) || [];
    }, [messages, selectedLanguage])

    return (
        <Grid container sx={{width: "100%", height: "100%", bgcolor: grey[800]}}>
            <Grid item xs={12} md={isChatOpen ? 9 : 12} sx={{height: '100%'}}>
                <DisplayActiveUsers
                    languages={languages}
                    session={session}
                    activeUsers={activeUsers}
                    onSelectLanguage={onSelectLanguage}
                    selectedLanguage={selectedLanguage}
                    isMicMuted={isMicMuted}
                    muteMicrophone={() => setIsMicMuted(true)}
                    unmuteMicrophone={() => setIsMicMuted(false)}
                    onChatDisplayHandle={() => setIsChatOpen(!isChatOpen)}
                    setAudioMessage={setAudioMessage}
                    currentMessage={currentMessage}
                    updateTalking={updateTalking}
                    />
            </Grid>

            {
                isChatOpen &&
                <Grid item xs={12} md={3} sx={{height: '100%'}}>
                        <DisplayChat
                            selectedLanguage={selectedLanguage}
                            session={session}
                            open={isChatOpen}
                            messages={translatedMessages}
                            message={message || audioMessage}
                            setMessage={setMessage}
                            onSendMessage={() => onSendMessage(message)}
                            user={authContext?.user}
                        />
                </Grid>
            }

        </Grid>
    )
}