import {useEffect, useState} from "react";
import {toast} from "react-toastify";

export const useAudioPlayer = ({isMuted}) => {
    const [messages, setMessages] = useState([]);
    const [currentMessage, setCurrentMessage] = useState(null);

    useEffect(() => {
        if (isMuted) {
            setMessages([]);
            setCurrentMessage(null);
        }
    }, [isMuted]);

    useEffect(() => {
        if (currentMessage == null && messages && messages?.length > 0) {
            startAudioMessage();
        }
    }, [messages])

    const startAudioMessage = () => {
        setMessages(prevState => {
            const items = prevState.filter(i => i.id !== currentMessage?.id);
            const item = items[0];
            setCurrentMessage(item || null);
            if (item && !item?.audio) {
                toast.info("Audio is not generated");
            }
            return items;
        });
    }

    const addMessage = (message) => {
        setMessages(prevState => {
            return [...prevState, message]
        });
    }

    const forcePlay = (message) => {
        setMessages([]);
        setCurrentMessage(message);
        if (!message?.audio) {
            toast.info("Audio is not generated", {id: message?.id});
        }
    }

    return [currentMessage, addMessage, startAudioMessage, forcePlay]
}