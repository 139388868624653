import {useEffect, useState} from "react";
import {Client} from "@stomp/stompjs";
import {AUTH_TOKEN} from "../axios/axios";

const useWebSocket = (url, id) => {
    const [receivedMessage, setReceivedMessage] = useState(null);
    const [connected, setConnected] = useState(false);
    const [activeUsers, setActiveUsers] = useState([]);
    const [websocket, setWebsocket] = useState(null);

    useEffect(() => {
        let client = null;
        if (id) {
            let onConnected = () => {
                // console.log(`Connected to websocket`)
                setConnected(true)
                try {
                    console.log("Connected");
                    client.subscribe(`/topic/session/${id}`, (msg) => {
                        if (msg?.body) {
                            var jsonBody = JSON.parse(msg.body);
                            if (jsonBody) {
                                setReceivedMessage(jsonBody);
                            }
                        }
                    });
                    client.subscribe(`/topic/active-users/${id}`, (msg) => {
                        if (msg?.body) {
                            var jsonBody = JSON.parse(msg.body);
                            if (jsonBody && jsonBody?.users) {
                                setActiveUsers(jsonBody?.users);
                            }
                        }
                    });
                    client.subscribe(`/topic/user-talking/${id}`, (msg) => {
                        if (msg?.body) {
                            var jsonBody = JSON.parse(msg.body);
                            if (jsonBody) {
                                setActiveUsers(prevState => {
                                    const items = [...prevState];
                                    const index = items.findIndex(i => i.username === jsonBody?.username);
                                    if (isFinite(index) && index >= 0) {
                                        items[index] = jsonBody;
                                    }

                                    return items;
                                });
                            }
                        }
                    });
                    client.subscribe(`/topic/user-muted/${id}`, (msg) => {
                        if (msg?.body) {
                            var jsonBody = JSON.parse(msg.body);
                            if (jsonBody) {
                                setActiveUsers(prevState => {
                                    const items = [...prevState];
                                    const index = items.findIndex(i => i.username === jsonBody?.username);
                                    if (isFinite(index) && index >= 0) {
                                        items[index] = jsonBody;
                                    }

                                    return items;
                                });
                            }
                        }
                    });
                    client.subscribe(`/topic/user-language/${id}`, (msg) => {
                        if (msg?.body) {
                            var jsonBody = JSON.parse(msg.body);
                            if (jsonBody) {
                                setActiveUsers(prevState => {
                                    const items = [...prevState];
                                    const index = items.findIndex(i => i.username === jsonBody?.username);
                                    if (isFinite(index) && index >= 0) {
                                        items[index] = jsonBody;
                                    }

                                    return items;
                                });
                            }
                        }
                    });

                    getActiveUsers();
                } catch (error) {
                    console.log("ERROR");
                    setConnected(false);
                }

            }

            let onDisconnected = () => {
                // console.log("Disconnected!!")
                setConnected(false)
                setWebsocket(null);
            }

            let SOCKET_URL = process.env.REACT_APP_WS;
            client = new Client({
                brokerURL: `${SOCKET_URL}?jwt=${localStorage.getItem(AUTH_TOKEN)}`,
                reconnectDelay: 5000,
                heartbeatIncoming: 4000,
                heartbeatOutgoing: 4000,
                onConnect: onConnected,
                onDisconnect: onDisconnected,
                onStompError: () => {
                    // console.log(`Failed to connect to topic (Stomp error): ${SOCKET_URL}`)
                    setConnected(false)
                },
                onWebSocketError: () => {
                    // console.log(`Failed to connect to topic (web socket error): ${SOCKET_URL}`);
                    setConnected(false)
                }
            });

            client.activate();
            setWebsocket(client);
        }

        return () => {
            if (client) {
                // client?.disconnect();
                client?.unsubscribe();
                client = null;
                setConnected(false)
            }
        }
    }, [url, id]);

    const getActiveUsers = () => {
        const params = { destination: `/app/active-users/${id}` }
        websocket?.publish(params);
    }

    const updateMuted = (activeUser) => {
        if (websocket) {
            const params = { destination: `/app/user-muted/${id}`, body: JSON.stringify(activeUser) }
            websocket?.publish(params);
        }
    }

    const updateTalking = (user) => {
        if (websocket) {
            const params = { destination: `/app/user-talking/${id}`, body: JSON.stringify(user) }
            websocket?.publish(params);
        }
    }
    const updateLanguage = (user) => {
        if (websocket) {
            const params = { destination: `/app/user-language/${id}`, body: JSON.stringify(user) }
            websocket?.publish(params);
            console.log("Sending language update", user);
        }
    }

    return {connected, receivedMessage, activeUsers, setActiveUsers, updateMuted, updateTalking, getActiveUsers, updateLanguage};
};

export default useWebSocket;