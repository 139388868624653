import axios from "../axios/axios";

export const SessionRepository = {
    create: () => {
        return axios.post(`/api/session`);
    },
    getAll: () => {
        return axios.get(`/api/session`)
    },
    get: (id) => {
        return axios.get(`/api/session/${id}`)
    },
    getActiveUsers: (id) => {
        return axios.get(`/api/session/${id}/users`)
    }
}