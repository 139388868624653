import axios from "../axios/axios";

export const UserRepository = {
    identifyUser: () => {
        return axios.post(`/api/user/me`);
    },
    edit: (username, user) => {
        return axios.put(`/api/user/${username}`, user)
    },
    login: (data) => {
        return axios.post(`/login`, data)
    },
    register: (data) => {
        return axios.post(`/api/user`, data)
    },
    getAllUsers: () => {
        return axios.get(`/api/user/all`);
    },
    searchUsers: (page, size, data) => {
        return axios.post(`/api/user/search?page=${page}&size=${size}`, data, {
            headers: {
                'Content-type': 'application/json'
            }
        });
    },
    activateUser: (username) => {
        return axios.patch(`/api/user/${username}`)
    },
    getRoles: () => {
        return axios.get(`/api/role`)
    },
    updateRoles: (username, roles) => {
        return axios.post(`/api/user/${username}/roles`, { roles }, {
            headers: {
                'Content-type': 'application/json'
            }
        })
    },
    updateUser: (username, data) => {
        return axios.put(`/api/user/${username}`, data)
    },
    changePassword: (username, data) => {
        return axios.put(`/api/user/${username}/change-password`, data)
    },
    resetPassword: (token, password) => {
        return axios.post(`/api/user/reset-password?token=${token}`, null, {
            headers: {
                password: password
            }
        })
    }
}
