import React, {useMemo} from "react";
import {Avatar, Box, IconButton, Stack, Toolbar, Tooltip, Typography} from "@mui/material";
import {grey, red} from "@mui/material/colors";
import {CallEnd, ChatBubble, CopyAll, Mic, MicOff, QuestionMark} from "@mui/icons-material";
import moment from "moment/moment";
import {Link} from "react-router-dom";
import * as Flags from "country-flag-icons/react/3x2";
import {AudioRecorder} from "./AudioRecorder";
import {copyToClipboard} from "../../../utils/functions";

export const DisplayActiveUsers = ({
                                       session,
                                       languages,
                                       activeUsers,
                                       isMicMuted,
                                       muteMicrophone,
                                       unmuteMicrophone,
                                       onChatDisplayHandle,
                                       selectedLanguage,
                                       onSelectLanguage,
                                       setAudioMessage,
                                       currentMessage,
                                       updateTalking
                                   }) => {

    const Flag = useMemo(() => {
        let Flag = null;
        if (Flags[selectedLanguage?.icon]) {
            Flag = Flags[selectedLanguage?.icon];
        }
        return Flag;
    }, [selectedLanguage])

    return (
        <Stack direction={"column"} sx={{width: "100%", height: "100%"}}>
            <Toolbar sx={{bgcolor: grey[900]}}>
                <Stack direction={"row"} sx={{width: "100%"}} gap={1} justifyContent={"space-between"}
                       alignItems={"center"}>
                    <Stack direction={"row"} gap={1} justifyContent={"space-between"} alignItems={"center"}>
                        <Tooltip title={"Copy URL"}>
                            <IconButton color={"light"} onClick={() => copyToClipboard(window.location.href)}>
                                <CopyAll/>
                            </IconButton>
                        </Tooltip>
                        <Typography color={"white"}>{session?.id}</Typography>
                    </Stack>
                    <Stack direction={"row"} alignItems={"center"} gap={1}>
                        <Typography color={"white"}>
                            {moment(session?.dateCreated).format('LLL')}
                        </Typography>
                        {
                            selectedLanguage && Flag && <IconButton onClick={onSelectLanguage}>
                                <Flag height={"20"}/>
                            </IconButton>
                        }
                    </Stack>
                </Stack>
            </Toolbar>
            <Box sx={{flex: 1, maxHeight: "100%", overflowY: "auto", position: "relative"}}>
                {
                    currentMessage &&
                    <Typography sx={{
                        width: "auto",
                        maxWidth: "80%",
                        px: 1,
                        textAlign: "center",
                        color: "#FFF",
                        position: "absolute",
                        bottom: 10,
                        left: "50%",
                        transform: 'translate(-50%, -10%)',
                        bgcolor: `rgba(255, 255, 255, 0.5)`
                    }}>
                        ({currentMessage?.sender?.username}): {currentMessage?.text}
                    </Typography>
                }
                <Stack direction={"row"} justifyContent={"center"} alignItems={"center"} gap={4} useFlexGap
                       flexWrap={"wrap"} sx={{m: 2}}>
                    {
                        activeUsers?.map(user => {
                            const lang = languages?.find(l => l.id === user?.language);
                            const UserFlag = lang ? Flags[lang.icon] : null;
                            return <Stack
                                key={user?.username}
                                direction={"column"}
                                alignItems={"center"}
                                gap={1}
                                className={user?.talking ? "talking" : ""}
                                sx={{
                                    borderRadius: 2,
                                    bgcolor: grey[900],
                                    p: 3,
                                    align: 'center',
                                    width: '230px',
                                    position: "relative",
                                    boxShadow: 20
                                }}
                            >
                                {UserFlag && <UserFlag  height={"10"} style={{position: "absolute", top: 10, left: 10, color: red[900]}} />}
                                {
                                    user?.muted ?
                                        <MicOff sx={{position: "absolute", top: 10, right: 10, color: red[900]}} fontSize={"15"} />
                                        :
                                        <Mic sx={{position: "absolute", top: 10, right: 10, color: "white"}} fontSize={"15"} />
                                }
                                <Avatar sx={{width: 150, height: 150, bgcolor: grey[800], color: grey[300]}}/>
                                <Typography sx={{wordWrap: "break-word", width: '100%'}} align={"center"}
                                            color={"white"} noWrap variant={"h5"}>
                                    {user?.username}
                                </Typography>
                            </Stack>
                        })
                    }
                </Stack>
            </Box>
            <Toolbar sx={{bgcolor: grey[900]}}>
                <Stack direction={"row"} gap={2} justifyContent={"center"} alignItems={"center"} sx={{width: "100%"}}>
                    <AudioRecorder
                        lang={selectedLanguage?.nmtCode}
                        id={session?.id}
                        updateMessage={(msg) => setAudioMessage(prevState => {
                            return `${prevState} ${msg}`
                        })}
                        isMicrophoneMuted={isMicMuted}
                        muteMicrophone={muteMicrophone}
                        unmuteMicrophone={unmuteMicrophone}
                        updateTalking={updateTalking}
                    />
                    <Tooltip title={"Close meeting"}>
                        <IconButton
                            sx={{bgcolor: red[600], color: grey[50], "&:hover": {bgcolor: red[800], color: grey[200]}}}
                            onClick={() => window.location.href = '/'}
                        >
                            <CallEnd/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={"Open/Close chat"}>
                        <IconButton color={"light"} onClick={onChatDisplayHandle}>
                            <ChatBubble/>
                        </IconButton>
                    </Tooltip>
                </Stack>
            </Toolbar>
        </Stack>

    )
}