import React, {useEffect, useState} from "react";
import {UserRepository} from "../repository/UserRepository";
import {toast} from "react-toastify";
import {AUTH_TOKEN} from "../axios/axios";
import {CircularProgress, Stack} from "@mui/material";

export const AuthContext = React.createContext({
    isAuth: null,
    user: {},
    roles: [],
    login: () => {
    },
    logout: () => {
    },
    checkUserHasAnyRoles: () => {
    },
    checkUserHasEveryRoles: () => {
    },
})

export const AuthContextProvider = props => {
    const [isAuthenticated, setIsAuthenticated] = useState(null);
    const [user, setUser] = useState(null);
    const [userRoles, setUserRoles] = useState([]);

    useEffect(() => {
        if (isAuthenticated === null) {
            identifyUser();
        }
    }, [])

    const identifyUser = () => {
        UserRepository.identifyUser()
            .then(response => {
                setIsAuthenticated(true);
                const data = response.data;
                setUser(data);
                setUserRoles(data.authorityList.map(authorityItem => authorityItem.authority))
            })
            .catch(error => {
                setIsAuthenticated(false);
                logoutHandler();
            })

    }

    const loginHandler = (username, password) => {
        UserRepository.login({username: username, password: password})
            .then(response => {
                localStorage.setItem(AUTH_TOKEN, response.data)
                setIsAuthenticated(true);
                identifyUser();
            })
            .catch(error => {
                toast.error("Login failed, try again!")
            });
    }

    const logoutHandler = () => {
        localStorage.clear()
        localStorage.removeItem(AUTH_TOKEN);
        setIsAuthenticated(false)
        setUser(null);
        setUserRoles([])
    }

    const checkUserHasAnyRoles = (roles) => {
        return userRoles.some(role => roles.includes(role));
    }

    const checkUserHasEveryRoles = (roles) => {
        return userRoles.every(role => roles.includes(role));
    }

    return (
        <AuthContext.Provider value={{
            login: loginHandler,
            logout: logoutHandler,
            isAuth: isAuthenticated,
            user: user,
            roles: userRoles,
            checkUserHasAnyRoles: checkUserHasAnyRoles,
            checkUserHasEveryRoles: checkUserHasEveryRoles
        }}>
            {props.children}
        </AuthContext.Provider>
    )
}
