import React, {useContext, useState} from "react";
import {Button, Divider, Grid, TextField, Typography} from "@mui/material";
import logo from "../../assets/logo.png";
import axios, {AUTH_TOKEN} from "../../axios/axios";
import {AuthContext} from "../../context/AuthContext";
import {Link} from "react-router-dom";

export const LoginPage = () => {
    const [password, setPassword] = useState("");
    const [username, setUsername] = useState("");
    const [error, setError] = useState(null);
    const {login} = useContext(AuthContext);

    const loginAttempt = () => {
        login(username, password);
    }

    return (
        <Grid container xs={12} justifyContent={"center"} alignItems={"center"} sx={{width: "100%", height: "100%"}}>
            <Grid item gap={2} sx={{maxWidth: "400px", width: "100%", borderColor: "divider", p: 3, textAlign: "center"}}>
                <img src={logo} alt={"logo"} width={"120px"} height={"auto"} />
                <Typography sx={{my: 2}} variant={"h5"}>Welcome to OpenBrain meet</Typography>
                <Typography sx={{my: 2}} variant={"subtitle2"}>Please enter your credentials</Typography>
                <TextField
                    label={"Username or Email"}
                    type={"text"}
                    sx={{my: 2}}
                    fullWidth
                    variant={"standard"}
                    value={username}
                    onChange={(event) => setUsername(event.target.value)}
                />
                <TextField
                    label={"Password"}
                    type={"password"}
                    sx={{my: 2}}
                    fullWidth
                    variant={"standard"}
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                />
                <Button
                    sx={{my: 2}}
                    fullWidth
                    variant={"contained"}
                    onClick={() => loginAttempt(password)}
                >
                    Login
                </Button>

                <Typography color={"error"}>
                    {error}
                </Typography>

                <Divider>
                    OR
                </Divider>

                <Button
                    fullWidth
                    component={Link}
                    to={"/register"}
                    variant={"text"}
                    size={"small"}
                    sx={{my: 2, color: "primary", textTransform: "uppercase"}}
                >
                    Register now
                </Button>
            </Grid>
        </Grid>
    )
}