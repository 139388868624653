import React, {useEffect, useRef} from "react";
import {blue, deepPurple, grey} from "@mui/material/colors";
import {
    Avatar,
    Box,
    Fade,
    IconButton,
    Stack,
    TextField,
    Toolbar,
    Typography
} from "@mui/material";
import {Send} from "@mui/icons-material";
import moment from "moment";
// import EmojiPicker from "emoji-picker-react";

export const DisplayChat = ({open, messages, message, setMessage, onSendMessage, user}) => {
    const bottomRef = useRef(null);
    // const [openEmoji, setOpenEmoji] = useState(false);

    useEffect(() => {
        if (messages && messages.length > 0 && bottomRef && bottomRef.current) {
            bottomRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages])

    return (
        <Fade in={open} timeout={300}>
            <Stack direction={"column"} sx={{width: "100%", height: "100%", bgcolor: grey[50]}}>
                <Toolbar sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <Typography variant={"h6"} fontWeight={"bold"}>Messages</Typography>
                </Toolbar>
                <Stack direction={"column"} sx={{flex: 1, maxHeight: "100%", overflowY: "auto", py: 1}} gap={1}>
                    {
                        messages?.map(messageItem => {
                            const isUser = user?.username === messageItem?.sender?.username;
                            const bgcolor =  isUser ? deepPurple[400] : blue[500];

                            return (
                                <Stack key={messageItem?.id} direction={isUser ? "row-reverse" : "row"} gap={1} alignItems={"center"} sx={{ px: 1 }}>
                                    <Avatar
                                        sx={{ bgcolor: bgcolor }}
                                    />
                                    <Box sx={{ bgcolor: bgcolor, borderRadius: 2, flex: 1, p: 1 }}>
                                        <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                                            <Typography sx={{color: "white", fontSize: 12, fontWeight: "bold"}}>{messageItem?.sender?.username}</Typography>
                                            <Typography sx={{color: "white", fontSize: 8}}>{moment(messageItem?.timeCreated).fromNow()}</Typography>
                                        </Stack>
                                        <Typography sx={{color: "white", fontSize: 12}}>{messageItem?.text}</Typography>
                                    </Box>
                                    <Avatar sx={{opacity: 0}}/>
                                </Stack>
                            )
                        })
                    }
                    <Box ref={bottomRef} />
                </Stack>
                <Toolbar disableGutters sx={{borderTop: 1, borderColor: 'divider', px: 1, mx: 0}}>
                    <TextField
                        size={"small"}
                        placeholder={"Enter your message"}
                        sx={{flex: 1, mr: 1, bgcolor: grey[200]}}
                        multiline
                        onKeyDown={(e) => {
                            if (e.key === "Enter" && !e.shiftKey && message) {
                                e.preventDefault();
                                onSendMessage();
                            }
                        }}
                        value={message}
                        onChange={(event) => setMessage(event.target.value)}
                        maxRows={1}
                    />
                    {/*<IconButton variant="contained" onClick={() => setOpenEmoji(true)}>*/}
                    {/*    <Mood />*/}
                    {/*</IconButton>*/}
                    {/*<Popover*/}
                    {/*    id={"emoji"}*/}
                    {/*    open={openEmoji}*/}
                    {/*    onClose={() => setOpenEmoji(false)}*/}
                    {/*    anchorOrigin={{*/}
                    {/*        vertical: 'bottom',*/}
                    {/*        horizontal: 'right',*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    <EmojiPicker onEmojiClick={(emojiData) => {*/}
                    {/*        setMessage(prevState => { return `${prevState}${emojiData.emoji}`});*/}
                    {/*        setOpenEmoji(false);*/}
                    {/*    }} />*/}
                    {/*</Popover>*/}
                    <IconButton color={"primary"} disabled={!message} onClick={onSendMessage}>
                        <Send/>
                    </IconButton>
                </Toolbar>
            </Stack>
        </Fade>
    )
}