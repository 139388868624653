import {useContext, useEffect, useState} from "react";
import {AuthContext} from "../context/AuthContext";

const useAuth = () => {
    // State and setters for debounced value
    const authContext = useContext(AuthContext);

    return [authContext.isAuth, authContext.user, authContext.roles, authContext.login, authContext.logout, authContext.checkUserHasAnyRoles, authContext.checkUserHasEveryRoles];
};

export default useAuth;