import React, {useContext, useEffect, useMemo, useState} from "react";
import {Box, CircularProgress, Modal, Stack, Typography} from "@mui/material";
import {useParams} from "react-router-dom";
import {SessionRepository} from "../../repository/SessionRepository";
import {ReportProblem} from "@mui/icons-material";
import {red} from "@mui/material/colors";
import {Session} from "./Session";
import useWebSocket from "../../hooks/useWebSocket";
import {AuthContext} from "../../context/AuthContext";
import {LanguageSupportRepository} from "../../repository/LanguageSupportRepository";
import {SelectLanguageModal} from "./components/SelectLanguageModal";
import {MessageRepository} from "../../repository/MessageRepository";
import {useAudioPlayer} from "../../hooks/useAudioPlayer";

export const SessionStateTypes = {
    LOADING: "loading",
    ESTABLISH_CONNECTION: "establish_connection",
    CONNECTED: "connected",
    CONNECTION_LOST: "connection_lost",
    NOT_FOUND: "not_found",
    PERMISSION_DENIED: "permission_denied",
    PERMISSION_DISMISSED: "permission_dismissed",
    ERROR: "error"
}

export const SessionWrapper = () => {
    const [state, setState] = useState(SessionStateTypes.LOADING);
    const [session, setSession] = useState(null);
    const [isMicMuted, setIsMicMuted] = useState(true);
    const [isChatOpen, setIsChatOpen] = useState(true);

    const [currentAudioMessage, addAudio, onFinishAudio, forcePlay] = useAudioPlayer(isMicMuted);

    const authContext = useContext(AuthContext);

    const {id} = useParams();

    const [messages, setMessages] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [selectedLanguage, setSelectedLanguage] = useState(null);
    const [openSelectLanguage, setOpenSelectLanguage] = useState(false);
    const [isTalking, setIsTalking] = useState(false);

    const { connected, receivedMessage, activeUsers, setActiveUsers, updateMuted, updateTalking, getActiveUsers, updateLanguage} = useWebSocket(process.env.REACT_APP_WS, id);

    useEffect(() => {
        if (receivedMessage) {
            const items = [...messages];
            const index = items.findIndex(i => i.id === receivedMessage?.id);

            if (isFinite(index) && index >= 0) {
                items[index] = receivedMessage;
            } else {
                items.push(receivedMessage);
            }
            if (receivedMessage.audio
                && receivedMessage?.language?.id === selectedLanguage?.id
                && receivedMessage?.sender?.username !== authContext?.user?.username
            ) {
                addAudio(receivedMessage);
            }
            setMessages(items);
        }
    }, [receivedMessage])

    useEffect(() => {
        if (authContext.user) {
            const item = activeUsers?.find(i => i?.username === authContext.user.username);
            if (!item) {
                getActiveUsers()
            }
        }
    }, [activeUsers])

    useEffect(() => {
        if (activeUsers && activeUsers.length > 0) {
            const items = [...activeUsers];
            const index = items.findIndex(i => i.username === authContext?.user?.username);
            if (isFinite(index) && index >= 0) {
                items[index].muted = isMicMuted;
                setActiveUsers(items);
                updateMuted(items[index]);
            }
        }
    }, [isMicMuted])

    useEffect(() => {
        if (activeUsers && activeUsers.length > 0) {
            const items = [...activeUsers];
            const index = items.findIndex(i => i.username === authContext?.user?.username);
            if (isFinite(index) && index >= 0 && items[index].language !== selectedLanguage?.id) {
                items[index].language = selectedLanguage?.id;
                setActiveUsers(items);
                updateLanguage(items[index]);
            }
        }
    }, [selectedLanguage, activeUsers])

    useEffect(() => {
        if (activeUsers && activeUsers.length > 0) {
            const items = [...activeUsers];
            const index = items.findIndex(i => i.username === authContext?.user?.username);
            if (isFinite(index) && index >= 0) {
                items[index].talking = isTalking;
                setActiveUsers(items);
                updateTalking(items[index]);
            }
        }
    }, [isTalking])

    useEffect(() => {
        if (id) {
            SessionRepository.get(id)
                .then(response => {
                    setSession(response.data);
                    setState(SessionStateTypes.ESTABLISH_CONNECTION);
                })
                .catch(() => {
                    setSession(null);
                    setState(SessionStateTypes.ERROR);
                })
            LanguageSupportRepository.getAll()
                .then(response => {
                    setLanguages(response.data);
                    setOpenSelectLanguage(true);
                })
                .catch(error => {
                    console.log(error);
                })
            MessageRepository.getAll(id)
                .then(response => setMessages(response.data))
                .catch(error => console.log(error))
        } else {
            setSession(null);
            setState(SessionStateTypes.NOT_FOUND)
        }
    }, [id])

    useEffect(() => {
        if (state === SessionStateTypes.ESTABLISH_CONNECTION) {
            navigator.getUserMedia({ video: false, audio: true }, stream => {
                stream.getTracks().forEach(track => {
                    setState(SessionStateTypes.CONNECTED);
                });
            }, (error) => {
                let newState = SessionStateTypes.PERMISSION_DENIED;
                if(error === 'DOMException: Permission dismissed'){
                    newState  = SessionStateTypes.PERMISSION_DISMISSED;
                }
                setState(newState);
            });
        }
    }, [state])

    useEffect(() => {
        if (id && connected) {
            SessionRepository.getActiveUsers(id)
                .then(response => setActiveUsers(response?.data?.users))
                .catch(error => console.log(error));
        }
    }, [connected, id])


    const audio = useMemo(() => {
        return <audio
            src={currentAudioMessage?.audio || ""}
            autoPlay
            // muted={isMicMuted}
            onEnded={() => onFinishAudio()}
        />
    }, [currentAudioMessage]);

    return (
        <>
            {audio}
            <SelectLanguageModal
                open={openSelectLanguage}
                handleClose={() => setOpenSelectLanguage(false)}
                languages={languages}
                onSelect={(language) => setSelectedLanguage(language)}
                selectedLanguage={selectedLanguage}
            />
            <Stack className={state !== SessionStateTypes.CONNECTED ? "session-loader" : "d-none"} sx={{ position: "absolute", width: "100%", height: "100%", zIndex: 10000}} justifyContent={"center"} alignItems={"center"} gap={2}>
                {
                    {
                        "loading": <><CircularProgress /> <Typography color={"white"} fontWeight={"bold"} variant={"h5"}>Trying to enter</Typography></>,
                        "establish_connection": <><CircularProgress /> <Typography  color="white"fontWeight={"bold"} variant={"h5"}>Trying to connect</Typography></>,
                        "not_found": <><Typography fontWeight={"bold"} color={"error"} variant={"h5"}>Meet not found, please refresh the page and check that the url is entered correctly</Typography></>,
                        "error": <><Typography fontWeight={"bold"} color={"error"} variant={"h5"}>There is error please try again later</Typography></>,
                        "connection_lost": <><Typography color={"white"} fontWeight={"bold"} variant={"h5"}>Connection lost please try again</Typography></>,
                        "permission_denied": <><ReportProblem sx={{color: red[500], fontSize: 50}} /><Typography color={red[500]} fontWeight={"bold"} variant={"h5"}>Please enable audio permissions</Typography></>,
                        "permission_dismissed": <><ReportProblem sx={{color: red[500], fontSize: 50}} /><Typography color={red[500]} fontWeight={"bold"} variant={"h5"}>Please enable audio permissions</Typography></>,

                    }[state]
                }
            </Stack>
            <Session
                languages={languages}
                selectedLanguage={selectedLanguage}
                onSelectLanguage={() => setOpenSelectLanguage(true)}
                session={session}
                activeUsers={activeUsers}
                messages={messages}
                setIsChatOpen={setIsChatOpen}
                setIsMicMuted={setIsMicMuted}
                isChatOpen={isChatOpen}
                isMicMuted={isMicMuted}
                currentMessage={currentAudioMessage}
                updateTalking={(value) => setIsTalking(value)}
            />
        </>
    )
}