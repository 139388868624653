import React, {useEffect, useState} from "react";
import {Mic, MicOff, Stop} from "@mui/icons-material";
import {IconButton, Tooltip} from "@mui/material";

const StateType = {
    paused: "paused",
    recording: "recording",
    muted: "muted",
    error: "error"
}

let bufferSize = 1024

export const AudioRecorder = ({id, lang, updateMessage, muteMicrophone, unmuteMicrophone, isMicrophoneMuted, updateTalking}) => {
    const [connection, setConnection] = useState(false);
    const [websocket, setWebsocket] = useState(null);
    const [audioChunks, setAudioChunks] = useState([]);
    const [input, setInput] = useState(null);
    const [processor, setProcessor] = useState(null);
    const [context, setContext] = useState(null);
    const [audioContext, setAudioContext] = useState(null);
    const [globalStream, setGlobalStream] = useState(null);

    useEffect(() => {
        if (lang && id && connection === false) {
            if (websocket) {
                websocket.close();
                setWebsocket(null);
            }
            if (context) {
                context?.close();
                setContext(null);
            }
            if (processor) {
                setProcessor(null);
            }
            initWebSocket();
        }
        return () => {
            if (websocket) {
                websocket?.close();
            }
        }
    }, [id, lang, connection]);

    useEffect(() => {
        if (context) {
            if (isMicrophoneMuted) {
                context.suspend().then(() => {}).catch(error => console.log(error));
            } else {
                context.resume().then(() => {}).catch(error => console.log(error));
            }
        } else {
            if (!isMicrophoneMuted){
                startRecording()
            }
        }

    }, [isMicrophoneMuted, websocket, context])

    const initWebSocket = () => {
        const websocket = new WebSocket(process.env.REACT_APP_VAD_WS + "?lang=" + lang?.toLowerCase());

        // WebSocket Definitions: executed when triggered webSocketStatus
        websocket.onopen = function () {
            console.log("voice wss connected to server");
            setConnection(true);
            //websocket.send("CONNECTED TO YOU");
        }

        websocket.onclose = function (e) {
            console.log("voice wss connection closed (" + e.code + ")");
            setConnection(false);
            muteMicrophone();
        }

        websocket.onmessage = function (e) {
            //console.log("message received: " + e.data);
            let result = undefined;
            try {
                updateMessage(e.data);
            } catch (e) {
                console.log(e, "error on parse")
            }

            if (typeof (result) !== 'undefined' && typeof (result.error) !== 'undefined') {
                console.log(result.error)
            }
        }
        setWebsocket(websocket);
    }

    function downsampleBuffer(buffer, sampleRate, outSampleRate) {
        if (outSampleRate == sampleRate) {
            return buffer;
        }
        if (outSampleRate > sampleRate) {
            throw 'downsampling rate show be smaller than original sample rate';
        }
        var sampleRateRatio = sampleRate / outSampleRate;
        var newLength = Math.round(buffer.length / sampleRateRatio);
        var result = new Int16Array(newLength);
        var offsetResult = 0;
        var offsetBuffer = 0;
        while (offsetResult < result.length) {
            var nextOffsetBuffer = Math.round((offsetResult + 1) * sampleRateRatio);
            var accum = 0,
                count = 0;
            for (var i = offsetBuffer; i < nextOffsetBuffer && i < buffer.length; i++) {
                accum += buffer[i];
                count++;
            }

            result[offsetResult] = Math.min(1, accum / count) * 0x7fff;
            offsetResult++;
            offsetBuffer = nextOffsetBuffer;
        }
        return result.buffer;
    }

    const startRecording = async () => {
        try {
            // if (!stream) {
            //     await register(await connect());
            // }
            if (!websocket) {
                return;
            }
            AudioContext = window.AudioContext || window.webkitAudioContext;
            const context = new AudioContext({
                // if Non-interactive, use 'playback' or 'balanced' // https://developer.mozilla.org/en-US/docs/Web/API/AudioContextLatencyCategory
                latencyHint: 'interactive',
            });

            setAudioContext(context);
            const processor = context.createScriptProcessor(bufferSize, 1, 1);
            processor.connect(context.destination);

            await context.resume();
            context.onstatechange = (event) => console.log(event?.currentTarget?.state);
            console.log("Context started...")
            setProcessor(processor);

            const processAudioData = (audioChannelData) => {
                // Calculate the average amplitude of the audio data
                const amplitude = audioChannelData.reduce((sum, value) => sum + Math.abs(value), 0) / audioChannelData.length;

                // Set the threshold value (adjust this according to your use case)
                const threshold = 0.1;

                // Check if the amplitude is above the threshold
                updateTalking(amplitude > threshold);
            };
            var handleSuccess = function (stream) {
                setGlobalStream(stream);

                let input = context.createMediaStreamSource(stream);
                input.connect(processor);
                setInput(input);

                processor.onaudioprocess = function (e) {
                    var left = e.inputBuffer.getChannelData(0);
                    var left16 = downsampleBuffer(left, 48000, 16000);

                    if (websocket && websocket?.readyState === 1 && isMicrophoneMuted === false) {
                        websocket.send(left16);
                        processAudioData(left)
                    }
                };
            };

            setContext(context);

            navigator.mediaDevices.getUserMedia({audio: true, video: false}).then(handleSuccess);

        } catch (error) {
            muteMicrophone();
        }
    };

    const stopRecording = () => {
        if (globalStream && globalStream?.getTracks().length > 0) {
            try {
                let track = globalStream.getTracks()[0];
                track.stop();

                websocket?.close();
                input.disconnect(processor);
                processor.disconnect(context.destination);
                context.close()
                    .then(function () {
                        console.log("Context closed")
                    })
                    .catch(error => console.log(error));
            } catch (error) {
                console.log(error, "ON STOP");
            } finally {
                setInput(null);
                setProcessor(null);
                setContext(null);
                setAudioContext(null);
                setConnection(false);
                muteMicrophone();
            }
        }

    };


    return (
        <Tooltip title={"Turn on/off microphone"}>
            <IconButton
                onClick={() => {
                    isMicrophoneMuted ? unmuteMicrophone() : muteMicrophone();
                }}
                disabled={!connection}
                color={"light"}
            >
                { isMicrophoneMuted ? <MicOff/> : <Mic /> }
            </IconButton>
        </Tooltip>
    )
}
